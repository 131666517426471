.toggle-bg {
  @apply bg-gray-200 dark:bg-gray-700 h-6 w-11 rounded-full cursor-pointer
}

.toggle-bg-small {
  @apply bg-gray-200 dark:bg-gray-700 h-5 w-9 rounded-full cursor-pointer
}

.toggle-bg:after {
  content: '';
  @apply absolute top-0.5 left-0.5 bg-white dark:bg-gray-850 shadow rounded-full h-5 w-5 transition;
}

.toggle-bg-small:after {
  content: '';
  @apply absolute top-0.5 left-0.5 bg-white dark:bg-gray-850 shadow rounded-full h-4 w-4 transition;
}

input:checked+.toggle-bg:after,
input:checked+.toggle-bg-small:after {
  transform: translateX(100%);
}

input:checked+.toggle-bg,
input:checked+.toggle-bg-small {
  @apply bg-green-400 dark:bg-green-500;
}

input:checked+.toggle-bg.toggle-bg-blue,
input:checked+.toggle-bg-small.toggle-bg-blue {
  @apply bg-blue-400 dark:bg-blue-500;
}

input:checked+.toggle-bg.toggle-bg-gray,
input:checked+.toggle-bg-small.toggle-bg-gray {
  @apply bg-gray-600 dark:bg-gray-300;
}

input:checked[disabled]+.toggle-bg,
input:checked[disabled]+.toggle-bg-small {
  @apply opacity-20 cursor-default;
}

input[disabled]+.toggle-bg,
input[disabled]+.toggle-bg-small {
  @apply opacity-20 cursor-default;
}